import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import TagManager from "react-gtm-module"

const tagManagerArgs = {
  gtmId: 'GTM-TN8NCSD',
  dataLayer: {
  }
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
